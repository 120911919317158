import store from '@/state/store'
import axios from 'axios'
import { authHeader } from '../helpers/auth-header'

export default [
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/pages/account/login'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters['auth/loggedIn']) {
                    // Redirect to the home page instead
                    next({ name: 'home' })
                } else {
                    // Continue to the login page
                    next()
                }
            },
        },
    },
    {
        path: '/register',
        name: 'register',
        component: () => import('../views/pages/account/register'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters['auth/loggedIn']) {
                    // Redirect to the home page instead
                    next({ name: 'home' })
                } else {
                    // Continue to the login page
                    next()
                }
            },
        },
    },
    {
        path: '/forgot-password',
        name: 'Forgot-password',
        component: () => import('../views/pages/account/forgot-password'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters['auth/loggedIn']) {
                    // Redirect to the home page instead
                    next({ name: 'home' })
                } else {
                    // Continue to the login page
                    next()
                }
            },
        },
    },
    {
        path: '/logout',
        name: 'logout',
        meta: {
            authRequired: true,
            async beforeResolve(routeTo, routeFrom, next) {
                try {
                    const userData = localStorage.getItem('user');
                    console.log(`${process.env.VUE_APP_APIURL}/admin/logout`);
                    const item = await axios.post(`${process.env.VUE_APP_APIURL}/admin/logout`, userData,  {
                        headers: authHeader(),
                    });
                    console.log(item);
                } catch (err) {
                    console.log(err);
                }

                next('/login');
            },
        },
    },
    {
        path: '/',
        name: 'home',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/dashboard/index')
    },

    // profile
    {
        path: '/profile',
        name: 'Profile',
        meta: { authRequired: false },
        component: () => import('../views/pages/account/profile')
    },

    // Admin
    {
        path: '/admin',
        name: 'Admin',
        meta: { authRequired: true },
        component: () => import('../views/pages/admin/index')
    },
    {
        path: '/admin/form/:id',
        name: 'Admin Form',
        meta: { authRequired: true },
        component: () => import('../views/pages/admin/form')
    },
    {
        path: '/admin/form/:id/password',
        name: 'Admin Edit Password',
        meta: { authRequired: true },
        component: () => import('../views/pages/admin/edit_password')
    },

    //agencies
    {
        path: '/agencies',
        name: 'Agency',
        meta: { authRequired: true },
        component: () => import('../views/pages/agency')
    },
    {
        path: '/agency/form/:id',
        name: 'Agency Form',
        meta: { authRequired: true },
        component: () => import('../views/pages/agency/form')
    },
    {
        path: '/agency/view/:id',
        name: 'Agency View',
        meta: { authRequired: true },
        component: () => import('../views/pages/agency/view')
    },
    // {
    //     path: '/agency/payout',
    //     name: 'Agency Payout',
    //     meta: { authRequired: true },
    //     component: () => import('../views/pages/agency/payout')
    // },

    //agent
    {
        path: '/agent',
        name: 'Agent',
        meta: { authRequired: true },
        component: () => import('../views/pages/agent')
    },
    {
        path: '/agent/form/:id',
        name: 'Agent Form',
        meta: { authRequired: true },
        component: () => import('../views/pages/agent/form')
    },
    {
        path: '/agent/view/:id',
        name: 'Agent View',
        meta: { authRequired: true },
        component: () => import('../views/pages/agent/view')
    },

    //member
    {
        path: '/member',
        name: 'Member',
        meta: { authRequired: true },
        component: () => import('../views/pages/member/index')
    },
    {
        path: '/member/view/:id',
        name: 'Member View',
        meta: { authRequired: true },
        component: () => import('../views/pages/member/view-detail')
    },
    // {
    //     path: '/member/add',
    //     name: 'Member Add Form',
    //     meta: { authRequired: true },
    //     component: () => import('../views/pages/member/add-new')
    // },
    // {
    //     path: '/member/:id/edit',
    //     name: 'Member Edit Form',
    //     meta: { authRequired: true },
    //     component: () => import('../views/pages/member/edit-detail')
    // },
    {
        path: '/member/:id',
        name: 'Member Edit Form',
        meta: { authRequired: true },
        component: () => import('../views/pages/member/add-new')
    },
    {
        path: '/address/edit/:id',
        name: 'Member Edit Address',
        meta: { authRequired: true },
        component: () => import('../views/pages/member/forms/member-address-form')
    },
    {
        path: '/address/add/:id',
        name: 'Member Add Address',
        meta: { authRequired: true },
        component: () => import('../views/pages/member/forms/member-address-form')
    },
    // {
    //     path: '/member-points',
    //     name: 'Member Point List',
    //     meta: { authRequired: true },
    //     component: () => import('../views/pages/member/points')
    // },


    //tenancy
    {
        path: '/tenancy',
        name: 'Tenancy',
        meta: { authRequired: true },
        component: () => import('../views/pages/tenancy/index'),
    },
    // {
    //     path: '/tenancy/view/:id',
    //     name: 'Tenancy View',
    //     meta: { authRequired: true },
    //     component: () => import('../views/pages/tenancy/view-detail')
    // },
    {
        path: '/tenancy/view-new/:id',
        name: 'Tenancy View New',
        meta: { authRequired: true },
        component: () => import('../views/pages/tenancy/view-page')
    },
    {
        path: '/tenancy/add-tenancy',
        name: 'Tenancy Add',
        meta: { authRequired: true },
        component: () => import('../views/pages/tenancy/add-tenancy')
    },
    // {
    //     path: '/tenancy/edit/:id',
    //     name: 'Tenancy Edit',
    //     meta: { authRequired: true },
    //     component: () => import('../views/pages/tenancy/edit-tenancy')
    // },
    {
        path: '/tenancy/edit-new/:id',
        name: 'Tenancy Edit New',
        meta: { authRequired: true },
        component: () => import('../views/pages/tenancy/update-tenancy-draft')
    },
    
    //ctc
    {
        path: '/ctc',
        name: 'Ctc',
        meta: { authRequired: true },
        component: () => import('../views/pages/ctc/index')
    },
    {
        path: '/ctc/view/:id',
        name: 'Ctc View',
        meta: { authRequired: true },
        component: () => import('../views/pages/ctc/view-detail')
    },
    {
        path: '/ctc/add-shipping/:id',
        name: 'Ctc Add Shipping',
        meta: { authRequired: true },
        component: () => import('../views/pages/ctc/add-shipping')
    },
    {
        path: '/ctc/create-ctc',
        name: 'Ctc Create',
        meta: { authRequired: true },
        component: () => import('../views/pages/ctc/create-ctc')
    },
    {
        path: '/ctc/edit/:id',
        name: 'Ctc Edit',
        meta: { authRequired: true },
        component: () => import('../views/pages/ctc/form')
    },
    
    //agreement
    {
        path: '/agreement/editor',
        name: 'Agreement Editor',
        meta: { authRequired: true },
        component: () => import('../views/pages/agreement/template')
    },
    
    //amenities
    {
        path: '/amenity',
        name: "Amenity",
        meta: { authRequired: true },
        component: () => import('../views/pages/amenity/index')
    },
    {
        path: '/amenity/form/:id',
        name: "Amenity Form",
        meta: { authRequired: true },
        component: () => import('../views/pages/amenity/form')
    },

    // Setting - Role
    {
        path: '/setting/general',
        name: 'General',
        meta: { authRequired: true },
        component: () => import('../views/pages/setting/general')
    },
    {
        path: '/setting/payment-methods',
        name: 'Payment Methods',
        meta: { authRequired: true },
        component: () => import('../views/pages/setting/payment-methods')
    },
    {
        path: '/setting/payment-methods/:id',
        name: 'Payment Methods Edit',
        meta: { authRequired: true },
        component: () => import('../views/pages/setting/payment-methods-edit')
    },
    {
        path: '/setting/role',
        name: 'Role',
        meta: { authRequired: true },
        component: () => import('../views/pages/setting/role')
    },
    {
        path: '/role/form/:id',
        name: 'Role Form',
        meta: { authRequired: true },
        component: () => import('../views/pages/setting/role_form')
    },
    
    {
        path: '/:pathMatch(.*)*',
        name: 'Error-404',
        meta: { authRequired: false },
        component: () => import('../views/pages/utility/error-404')
    },
]
