export const state = {
    error: null
};

export const getters = {
    getError: state => state.error,
}

export const mutations = {
    ADDERROR(state, error) {
        state.error = error;
    },
    CLEARERROR(state, error) {
        state.error = error;
    },
};

export const actions = {
    addError({ commit }, error) {
        console.log(error);
        commit('ADDERROR', error);
    },
    clearError({ commit }) {
        commit('CLEARERROR');
    },
};
