export const state = {
    modalCallBack: null,
    modalMessage: "",
    modalMessageOpen: false,
    modalConfirmOpen: false,
    modelRedirect: {
        redirect: false,
        link: "",
    }
};

export const getters = {
    modalCallback: state => state.modalCallBack,
    modalMessage: state => state.modalMessage,
    modalMessageOpen: state => state.modalMessageOpen,
    modalConfirmOpen: state => state.modalConfirmOpen,
    modelRedirect: state => state.modelRedirect,
}

export const actions  = {
    openMessageModal({ commit }, {message, redirect = ""}) {
        commit('ADD_LINK_REDIRECT', redirect);
        commit('SHOW_MESSAGE_MODAL', message);
    },
    openConfirmModal({ commit }, obj) {
        commit('SHOW_CONFIRM_MODAL', obj);
    },
    closeMessageModal({ commit }) {
        commit('HIDE_MESSAGE_MODAL') ;
    },
    closeConfirmModal({ commit }) {
        commit('HIDE_CONFIRM_MODAL');
    },
}

export const mutations = {
    SHOW_MESSAGE_MODAL(state, message) {
        state.modalMessageOpen = true;
        state.modalMessage = message;
    },
    SHOW_CONFIRM_MODAL(state, obj) {
        state.modalConfirmOpen = true;
        state.modalMessage = obj.message;
        state.modalCallBack = obj.callback;
    },
    HIDE_MESSAGE_MODAL(state) {
        state.modalMessageOpen = false;
    },
    HIDE_CONFIRM_MODAL(state) {
        state.modalConfirmOpen = false;
    },
    ADD_LINK_REDIRECT(state, url) {
        if (url != "") {
            state.modelRedirect.link = url;
            state.modelRedirect.redirect = true;
        }
    },
  };